$border: 1px solid rgba(0, 0, 0, 0.3);
$print-border: 1px solid black;

.form {
  position: relative;
  padding: 30px;
  .stepper {
    display: none;
  }
}

@media print {
  .form {
    margin: 0;
    padding-bottom: 500px;
    height: 296.3mm;
    hr {
      border-top: $print-border;
    }
    .total {
      border: 1px solid #707070;
    }
    .stepper {
      display: block;
    }
  }
}
