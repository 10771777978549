$border: 1px solid rgba(0, 0, 0, 0.3);
$print-border: 1px solid black;

.form {
  position: relative;
  width: 210mm;
  margin: 0 auto;

  .footer {
    display: none;
    width: calc(100% - 60px);
    position: absolute;
    bottom: 30px;
    font-size: 12px;
    padding-top: 10px;
    & > div {
      text-align: center;
    }
    & > div:first-of-type {
      text-align: left;
      color: '#505050';
      font-size: 12px;
      padding-bottom: 20px;
    }
    hr {
      margin: 10px 0;
    }
    & .details {
      .detail {
        display: inline-block;
        margin-top: 5px;
        div {
          width: 100%;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media print {
  .form {
    margin: 0;
    padding-bottom: 500px;
    height: 296.3mm;
    .total {
      border: 1px solid #707070;
    }
    th,
    td {
      border-color: #707070;
    }
    hr {
      border-top: $print-border;
    }
    .footer {
      display: block;
      padding-left: 30px;
    }
  }
  .pageBreak {
    page-break-before: always;
  }
}
