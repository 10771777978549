$border: 1px solid rgba(0, 0, 0, 0.3);
$print-border: 1px solid black;

.form {
  height: 296mm;
  position: relative;
  padding: 30px;
  hr {
    border-top: $border;
  }
  .header {
    display: flex;
    justify-content: space-between;
    .title {
      color: black;
      font-weight: bold;
      font-size: 1.5rem;
      span {
        margin-left: 10px;
        font-weight: 400;
        font-size: 1rem;
      }
    }
    .date {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
    }
  }
  .info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    & > div:nth-of-type(1) {
      width: 38%;
    }
    & > div:nth-of-type(2) {
      width: 58%;
      display: flex;
      & > div {
        width: 50%;
      }
    }
  }
  .tables {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    font-size: 14px;
    & > div:nth-of-type(1) {
      width: 38%;
    }
    & > div:nth-of-type(2) {
      width: 58%;
    }
    table {
      border-collapse: collapse;
      border-top: $border;
      width: 100%;
      text-align: left;
      thead {
        th {
          border-bottom: $border;
          padding: 10px 8px;
        }
      }
      tbody {
        tr:nth-of-type(odd) td {
          background-color: rgba(0, 0, 0, 0.05);
        }
        td {
          border-bottom: $border;
          padding: 10px 8px;
        }
      }
    }
  }
  .detail {
    margin-top: 15px;
    div {
      display: inline-block;
    }
    div:nth-of-type(1) {
      width: 100px;
    }
    div:nth-of-type(2) {
      width: calc(100% - 100px);
    }
  }
  .checklist {
    font-size: 14px;
    margin-top: 20px;
    width: 100%;
    border: $border;
    border-collapse: collapse;
    th,
    td {
      padding: 20px 10px;
      border: $border;
    }
    tr > td:nth-of-type(2),
    tr > td:nth-of-type(3) {
      text-align: center;
    }
    tr > th:nth-of-type(4),
    tr > td:last-of-type {
      width: 35%;
      text-align: left;
    }
    tr > td:last-of-type {
      padding: 5px 10px;
    }
  }
  .footer {
    width: calc(100% - 40px);
    position: absolute;
    bottom: 30px;
    font-size: 10px;
    padding-top: 10px;
    display: none;
    .stamps {
      height: 60px;
      font-size: 14px;
      & > div {
        width: 25%;
        text-align: left;
      }
    }
    & > div {
      text-align: center;
    }
    & .details {
      .detail {
        display: inline-block;
        margin-top: 0;
        div {
          width: 100%;
          margin-right: 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

@media print {
  .form {
    margin: 0;
    height: 296mm;
    hr {
      border-top: $print-border;
    }
    .tables {
      table {
        border-top: $print-border;
        thead {
          th {
            border-bottom: $print-border;
          }
        }
        tbody {
          td {
            border-bottom: $print-border;
          }
        }
      }
    }
    .checklist {
      border: $print-border;
      th,
      td {
        border: $print-border;
      }
    }
    .footer {
      display: block;
      .detailsBlock {
        padding-top: 12px;
        border-top: $print-border;
      }
    }
  }
}
