@media print {
  body > *:not(.printable) {
    display: none;
  }

  .printable {
    width: 100%;
    :global .MuiBackdrop-root {
      display: none;
    }
    :global .MuiPaper-root {
      margin: 0;
      width: 100%;
    }
  }
}
