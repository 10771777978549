:global(.lg-backdrop) {
  z-index: 1100;
}

.customGallery {
  z-index: 1105;
  & .lg-toolbar {
    top: 70;
  }
}

.imageContainer {
  width: 240px;
  height: 144px;
  border-radius: 7px;
  position: relative;
  img {
    width: 100%;
    height: 144px;
    object-fit: 'cover';
    object-position: 'center';
  }
  .deleteButton {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.swiper {
  & .swiper-pagination-bullet {
    width: 10;
    height: 10;
    background-color: indigo;
  }
}
