.receipt {
  box-sizing: border-box;
  width: 58mm;
  min-height: 10mm;
  margin: 0;
  font-family: serif;
  margin-bottom: 8px;

  .title {
    width: 100%;
    height: 11mm;
    padding: 0 5mm;
    -webkit-print-color-adjust: exact;
    img {
      width: 100%;
    }
  }
  .type {
    text-align: center;
    font-size: 20px;
  }
  .range {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }
  .sn {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 35px;
  }
  .info {
    padding: 0 2.5mm;
    font-size: 12px;
    font-weight: normal;
  }
  .panel {
    display: flex;
    justify-content: space-between;
    .left {
      flex-basis: 1;
    }
    .right {
      flex-basis: 1;
      text-align: right;
    }
  }
  .barcode {
    padding: 2mm 2.5mm 0;
    img {
      width: 100%;
    }
  }

  .qrcode {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 1mm 0 0;
    .right {
      text-align: right;
    }
    img {
      padding: 0 2.5mm;
      width: 100%;
    }
  }

  .fl,
  .fr {
    display: inline-block;
    padding: 0;
    margin: 0;
    line-height: 1px;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }
}

@media print {
  html,
  body {
    width: 58mm;
    background-color: white;
  }

  .receipt {
    margin: 0;
    padding: 0 0mm 0 2mm;
    .title {
      height: 9.5mm;
      padding: 0 3mm;
    }
  }
}
