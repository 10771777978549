.form {
  width: 148mm;
  height: 210mm;
  margin-bottom: 10px;
  position: relative;
}
.control {
  display: block;
}

@media print {
  @page {
    size: A5 portrait;
  }
  .form {
    margin: 0;
    width: 148mm;
    height: 209.61mm;
    break-after: always;
  }
  .control {
    display: none;
    box-shadow: none;
  }
}
